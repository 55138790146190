import * as HttpService from './http.service';
import * as URL from './url.service';

export const loginUser = (data) => HttpService.postWithOutAuth(URL.USER_LOGIN_URL, data);

export const createUser = (data) => HttpService.postWithAuth(URL.CREATE_USER_URL, data);

export const getPaginatedUsers = (offset, limit, role, token, phrase) => HttpService
    .getWithAuth(URL.GET_PAGINATED_USER_URL(offset, limit, role, phrase), token);
export const getPaginatedUsersForCompliance = (offset, limit, token, phrase) => HttpService
    .getWithAuth(URL.GET_PAGINATED_USER_FOR_COMPLIANCE_URL(offset, limit, phrase), token);
export const getChatTokenForCompliance = ({ userId, token }) => HttpService
    .getWithAuth(URL.GET_CHAT_TOKEN_FOR_COMPLIANCE_URL(userId), token);

export const updateUser = (id, data) => HttpService.putWithAuth(URL.UPDATE_USER_URL(id), data);

export const userDetails = () => HttpService.getWithAuth(URL.GET_USER_DETAILS_URL());

export const sendForgotPasswordEmail = (email) => HttpService.postWithOutAuth(URL.SEND_FORGOT_PASSWORD_EMAIL_URL, { email });

export const resetUserPassword = (data) => HttpService.postWithOutAuth(URL.RESET_USER_PASSWORD(), data);

export const editUserProfile = (id, data) => HttpService.putWithAuth(URL.EDIT_USER_PROFILE_URL(id), data);

export const updateUserStatus = (id, data) => HttpService.patchWithAuth(URL.UPDATE_USER_STATUS_URL(id), data);
