import {
    signInWithCustomToken
} from 'firebase/auth';
import {
    doc,
    serverTimestamp
} from 'firebase/firestore';
import { yupValidator } from 'lib/yup-validator';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from 'atoms/Button';
import Checkbox from 'atoms/Checkbox';
import FormGroup from 'atoms/FormGroup';
import Heading from 'atoms/Heading';
import Logo from 'atoms/Logo';
import Text from 'atoms/Text';

import axios from 'axios';
import { complianceRoles, windowFeatures } from 'lib/constants';
import LogRocket from 'logrocket';
import Link from 'next/link';
import TermsAndCondition from 'organisms/TermsAndCondition';
import { omit } from 'ramda';
import { Slash } from 'react-feather';
import { toast } from 'react-toastify';
import { getAuth, setAuth, setRefreshToken } from 'services/auth.service';
import {
    auth, db, getDocs, setDocs, updateDocs
} from 'services/firebase.service';
import { socketConnect, socketEmit } from 'services/socket';
import { loginUser, sendForgotPasswordEmail } from 'services/user.service';
import { trackEvent } from '../helper/mixPanel';

const LoginNew = ({ }) => {
    const [isPwdForgot, setIsPwdForgot] = React.useState(true);
    const onForgotPwd = () => {
        setIsPwdForgot(false);
    };
    const [loading, setLoading] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [isTermsAgreed, setIsTermsAgreed] = useState(true);
    const [isSelected, setIsSelected] = useState(true);
    const loginSchema = yup.object().shape({
        email: yup.string().required('Email is mandatory').email('Email is not valid'),
        password: yup.string().required('Password is mandatory')
    });
    const forgotPasswordSchema = yup.object().shape({
        email: yup.string().required('Email is mandatory').email('Email is not valid')
    });
    const [showDeactivatedError, setShowDeactivatedError] = useState(false);

    const finalSchema = isPwdForgot ? loginSchema : forgotPasswordSchema;
    const {
        handleSubmit,
        formState: { errors, isValid },
        setError,
        register,
        trigger
    } = useForm({
        resolver: yupValidator(finalSchema)
    });

    const handleActivity = (isFocusChanged) => {
        const auth = getAuth();
        if (!isFocusChanged || !auth) return;
        window.location.reload();
    };

    const openWindows = () => {
        window.open('/hoot', 'hoot', windowFeatures);
        window.open('/dashboard', '_self');
    };
    useEffect(() => {
        const handleActivityTrue = () => handleActivity(true);
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                handleActivity(true);
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('focus', handleActivityTrue);
        document.addEventListener('focus', handleActivity);

        return () => {
            window.removeEventListener('focus', handleActivityTrue);
            document.removeEventListener('focus', handleActivityTrue);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    const backtoLogin = () => {
        setIsPwdForgot(true);
    };

    const saveClientDetails = async () => {
        let clientDetails;
        try {
            const apiResponse = await axios('https://ipapi.co/json/');
            if (apiResponse.status === 200) {
                clientDetails = apiResponse.data;

                socketEmit('/users/sessions', { clientDetails: { ...clientDetails, timestamp: Date.now() } });
            }
            return apiResponse;
        } catch (e) {
        } finally {
            if (!clientDetails) socketEmit('/users/sessions', { clientDetails: { timestamp: Date.now() } });
        }
    };

    const submit = async ({ email, password, terms = false }) => {
        if (loading) return;
        setLoading(true);
        setShowDeactivatedError(false);
        if (!isPwdForgot) {
            const response = await sendForgotPasswordEmail(email);
            setLoading(false);
            if (response.status) {
                toast(response.data.message, { type: 'success' });
            } else if (response?.error?.error.length) {
                toast(response.error.error, { type: 'error' });
            } else {
                toast(response.message, { type: 'error' });
            }
            return;
        }
        const response = await loginUser({ email, password, terms });
        if (response.status && response.data.entity) {
            const {
                name, companyName, role, sessionId
            } = response.data.entity;
            LogRocket.identify(response.data.entity.id, {
                email, name, companyName, role, sessionId
            });
            setAuth(omit(['refreshToken'], response.data.entity));
            setRefreshToken(response.data.entity.refreshToken);
            trackEvent('login', {
                'User Id': response?.data?.entity?.id, Name: name, Company: companyName, Role: role
            });
            let res;
            try {
                res = await signInWithCustomToken(
                    auth,
                    response.data.entity.fbToken
                );
            } catch (loginError) {
                console.error('Login error:', loginError);
            }
            try {
                if (res) {
                    const userSnapshot = await getDocs(doc(db, 'users', response.data.entity.id));
                    if (!userSnapshot || !userSnapshot.exists()) {
                        await setDocs(doc(db, 'users', response.data.entity.id), {
                            name: response.data.entity.name,
                            sname: response.data.entity.name.toLowerCase(),
                            createdAt: serverTimestamp(),
                            profileUrl: response.data.entity.profileUrl,
                            companyName: response.data.entity.companyName,
                            uid: res.user.uid,
                            lastSeen: serverTimestamp(),
                            metadata: {
                                userId: response.data.entity.id,
                                companyId: response.data.entity?.companyId,
                                role: response.data.entity.role
                            },
                            role: null,
                            updatedAt: serverTimestamp()
                        });
                    } else if (userSnapshot.exists()) {
                        const userDocRef = doc(db, 'users', response.data.entity.id);
                        await updateDocs(userDocRef, {
                            name: response.data.entity.name,
                            sname: response.data.entity.name.toLowerCase(),
                            profileUrl: response.data.entity.profileUrl,
                            companyName: response.data.entity.companyName,
                            lastSeen: serverTimestamp(),
                            metadata: {
                                userId: response.data.entity.id,
                                companyId: response.data.entity?.companyId,
                                role: response.data.entity.role
                            },
                            updatedAt: serverTimestamp()
                        });
                    }
                }
            } catch (error) { console.log(error); }
            socketConnect();
            await saveClientDetails();
            if (complianceRoles.includes(role)) {
                window.open('/compliance/users', '_self');
            } else {
                openWindows();
            }
        } else if (response?.error?.error === 'User Deactivated') setShowDeactivatedError(true);
        else if (response?.error?.error === 'terms') {
            setShowTerms(true);
        } else {
            setError('password', {
                type: 'manual',
                message: response.message || 'Failed to Login'
            });
        }
        setLoading(false);
    };
    const onEnterKey = (event) => {
        if (event.keyCode === 13) {
            handleSubmit(submit)();
        }
    };

    const handleTerms = () => {
        setIsTermsAgreed(!isTermsAgreed);
    };
    const onChange = () => {
        setIsSelected(!isSelected);
    };
    return (
        <div className='h-[100vh] bg-[url("/images/login-bg.gif")] relative bg-basic-200 bg-no-repeat flex items-center bg-cover'>

            <div className='absolute top-0 left-6'>
                <Link href={'/'} passHref>
                    <Logo src="/images/logo.svg" alt="DEON" width="64" height="40" className="py-5" />
                </Link>
            </div>

            <div className='pb-8 bg-gray-600 bg-opacity-90 px-8 max-w-[400px] w-full flex flex-col rounded-md justify-between ml-[6%]'>
                <div className='mb-10 text-gray-100 pt-8 text-center'>
                    <Heading type='h2' className='font-bold'>
                        {isPwdForgot ? 'Sign in' : 'Forgot Password?'}
                    </Heading>

                    {isPwdForgot || (
                        <div className='max-w-[300px] mx-auto mt-4'>
                            <Text variant="bodySmall">
                                Please enter your registered email address to receive password reset instructions.
                            </Text>
                        </div>
                    )}
                </div>
                <FormGroup
                    label="Email address"
                    placeholder="Enter your email"
                    type="email"
                    register={register}
                    errors={errors}
                    required={true}
                    className="mb-4 flex-col"
                    name="email"
                    inputClass='w-full'
                    autoFocus={true}
                    onKeyDown={onEnterKey}
                    autoComplete="on"
                />
                {isPwdForgot && (
                    <FormGroup
                        label="Password"
                        placeholder="Enter password"
                        type="password"
                        register={register}

                        errors={errors}
                        required={true}
                        className="mb-4 flex-col"
                        name="password"
                        inputClass='w-full'
                        onKeyDown={onEnterKey}
                    />
                )}
                {isPwdForgot && (
                    <div className='flex justify-between items-center mb-3'>
                        <Checkbox
                            isSelected={isSelected}
                            handleChange={onChange}
                            checkBoxLabel="Remember Me"
                        />
                        <Button
                            type="borderLess"
                            className="bg-transparent !text-primary-300 !p-0 !text-sm !font-normal hover:bg-transparent active:bg-transparent hover:underline"
                            label="Forgot Password?"
                            onClick={onForgotPwd}

                        />
                    </div>
                )}
                {isPwdForgot
                    && <Checkbox
                        isSelected={isTermsAgreed}
                        handleChange={handleTerms}
                        checkClassName="mt-0.5 min-w-4"
                        checkBoxContainerClassName='!items-start mb-8'
                        checkBoxLabel={<Text variant="bodySmall" className="text-gray-100">You acknowledge that you have read and agree to
                            our <a className='text-primary-300 hover:underline' href="/termandcondition" target="_blank">Terms and Conditions</a></Text>}
                    />}
                <Button
                    label={isPwdForgot ? 'Login' : 'Continue'}
                    className="w-full flex justify-center cursor-pointer"
                    onClick={() => { trigger(); if (!isValid) return; handleSubmit(submit)(); setIsPwdForgot(true); }}
                    loading={loading}
                />
                {isPwdForgot || (
                    <Button
                        type="borderLess"
                        size='small'
                        className="!text-primary-300 mt-7 !font-medium mx-auto"
                        label="Back to login"
                        onClick={backtoLogin}
                    />
                )}
            </div>
            {
                showDeactivatedError && <div className='bg-error-300 py-[22px] px-3.5 rounded flex items-center gap-2 absolute left-[30vw] top-16'>
                    <Slash size={20} className='text-basic-100' />
                    <Text variant="bodySmall" className="text-basic-100">Your account has been blocked and you should contact the administrator for assistance.</Text>
                </div>
            }
            <TermsAndCondition show={showTerms} buttonLoading={loading} onConfirm={() => handleSubmit((data) => submit({ ...data, terms: true }))()} handleChange={handleTerms} disabled={!isTermsAgreed} isSelected={isTermsAgreed} onClose={() => setShowTerms(false)} />
        </div >
    );
};

export default LoginNew;
