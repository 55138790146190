import Login from '@/components/LoginNew';
import { complianceRoles } from 'lib/constants';

const login = () => (
    <Login />
);

export async function getServerSideProps({ req: { cookies } }) {
    const redirect = { redirect: { destination: '/dashboard', permanent: false } };
    try {
        const auth = JSON.parse(cookies.AUTH);
        if (auth.token) {
            if (complianceRoles.includes(auth.role)) {
                return { redirect: { destination: '/compliance/users', permanent: false } };
            }
            return redirect;
        }
        return redirect;
    } catch (err) { return { props: {} }; }
}

export default login;
