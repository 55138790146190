import PropTypes from 'prop-types';
import Text from './Text';

function FormGroup({
    id, required = false, name, type, label, placeholder, register, errors, inputClass = 'w-full', errorWidth,
    disabled = false, className, appendData, labelWidth = 'w-max', autoFocus = false, onKeyDown = () => { },
    value, labelClassName, registerArgs, labelColor, secondaryLabel, autoComplete, currency, currencyClass
}) {
    return (
        <div className={`flex gap-2 ${className}`}>
            {label
                && <label
                    className={`flex-none ${labelColor || 'text-gray-100'} text-sm select-none ${labelWidth
                        || 'w-max'} ${labelClassName}`} htmlFor={name}>
                    {label}
                    {required && <span className="text-error-300 mr-1">*</span>}
                    {secondaryLabel}
                </label>
            }
            <div className="relative flex-1">
                <input
                    id={id ?? name}
                    name={name}
                    type={type}
                    value={value}
                    placeholder={placeholder || label}
                    autoFocus={autoFocus}
                    onKeyDown={onKeyDown}
                    disabled={disabled}
                    autoComplete={autoComplete || 'off'}
                    {...(register && (registerArgs ? { ...register(name, registerArgs) } : { ...register(name) }))}
                    className={`h-10 text-sm px-3 font-normal leading-5 bg-gray-500 rounded-md outline-none placeholder:text-gray-200
                         text-gray-100 border border-transparent hover:border-primary-300 focus:border-primary-300
                          disabled:text-gray-300 disabled:bg-gray-700 ${inputClass} ${errors[name] ? 'is-invalid' : ''}`}
                />
                {currency && <Text variant="caption" className={`${currencyClass} font-medium text-gray-100 absolute top-0.5 right-0.5 w-16 h-9  grid place-content-center rounded-r-md bg-gray-600 `}>{currency}</Text>}
                {errors[name] && <div className={`text-xs text-error-300 mt-1 ${errorWidth && 'w-[480px]'}`}> {errors[name].message} </div>}
            </div>
            {appendData && appendData}
        </div>
    );
}

export default FormGroup;
FormGroup.propTypes = {
    required: PropTypes.bool,
    name: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    register: PropTypes.func,
    // errors: PropTypes.object,
    inputClass: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    appendData: PropTypes.string,
    labelWidth: PropTypes.string,
    autoFocus: PropTypes.bool,
    onKeyDown: PropTypes.func,
    value: PropTypes.string,
    labelColor: PropTypes.string,
    labelClassName: PropTypes.string,
    secondaryLabel: PropTypes.string
};
