import PropTypes from 'prop-types';
import { useRef } from 'react';
import { X } from 'react-feather';
import Heading from '../atoms/Heading';
import useClickOutside from '../components/common/ClickOutSide';

const DialogBox = ({
    children,
    title,
    subTitle,
    titleStyle,
    width,
    height,
    onClick,
    close,
    iconClass,
    radius,
    isClose,
    className,
    dialogClass,
    customPadding,
    bodySpacing,
    headingClass,
    clickOutside,
    subTitleStyle
}) => {
    const dialogRef = useRef();
    useClickOutside(dialogRef, clickOutside || close);

    return (
        <div className={`dialog fixed flex items-center justify-center w-full h-full top-0 left-0 bg-basic-200 bg-opacity-90 z-[1000] px-4 md:px-0 ${className}`}>
            <div ref={dialogRef} className={`bg-gray-600 h-auto 
                ${customPadding || 'py-8 px-4 md:px-8'}
                ${dialogClass}
                ${radius || 'rounded-md'}
                ${width}
                ${height}`}>

                {(title || subTitle) && <div className={`dialog-header flex items-center justify-between gap-10 ${bodySpacing || 'mb-9'}`}>
                    {title && (
                        <div className={`w-full ${headingClass}`}>

                            {title && <Heading type='h4' className={`text-gray-100 flex-1 font-semibold ${titleStyle}`}>{title}</Heading>}
                            {subTitle && (
                                <Heading type='h5' className={`text-gray-100 flex-1 font-normal mt-1 ${subTitleStyle} ${titleStyle}`}>{subTitle}</Heading>
                            )}
                        </div>
                    )}
                    {isClose && (
                        <div className={`ml-auto ${iconClass} flex items-center justify-center cursor-pointer`} onClick={close}>
                            <X size={24} className={'text-gray-50'} />
                        </div>
                    )}
                </div>}
                {children}
            </div>
        </div>
    );
};

DialogBox.defaultProps = {
    width: 'w-max',
    height: 'h-max',
    onClick: () => true,
    close: () => true,
    isClose: false,
    className: '',
    dialogClass: ''
};

DialogBox.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    titleStyle: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    onClick: PropTypes.func,
    close: PropTypes.func,
    iconClass: PropTypes.string,
    radius: PropTypes.string,
    isClose: PropTypes.bool,
    className: PropTypes.string,
    dialogClass: PropTypes.string,
    customPadding: PropTypes.string,
    headingClass: PropTypes.string
};

export default DialogBox;
